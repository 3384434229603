.datatable-scroll-container {
  .cOJDgi {
    max-height: 500px; /* Set your desired fixed height here */
  }
}

.trail-balance-footer {
  background-color: #fff !important;
  color: #000 !important;
}

.amount-table {
  text-align: end;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

@media print {
  .trail-balance {
    visibility: hidden;
  }
  .print-datatable-scroll-container {
    visibility: visible;
    color: black;
  }
  .rdt_Table
  {
    color: black;

  }

  .rdt_TableHead {
    color: black;

  }
  .rdt_TableCol {
    color: black;

  }

}
